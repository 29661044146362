export const CLEVERSHOP_CONTROL_ENDPOINT = 'https://api.clevershop-control.estests.com/'
export const UPLOAD_SERVICE = 'https://upload-dev.v1.api.cleversell.io/'
export const DEV = 'https://api.dev.hbshop.co'
export const SUP = 'https://api.sup.qpix.io/'
export const SUP_MS = 'https://stitch.csms-dev.cleversell.io/tenets/sup'
export const QUIX = 'https://api.quix.qpix.io/'
export const QUIX_PROD = 'https://api.quix-prod.qpix.io/'
export const DOC_TALK = 'https://stitch.csms-dev.cleversell.io/tenets/doc-talk'
export const EST = 'https://est-api.estests.com/tenets/est'
export const GEMMA = 'https://stitch.csms-dev.cleversell.io/tenets/gemma'
export const CLEVERSELL = 'https://stitch.csms-dev.cleversell.io/tenets/cleversell-dev'
export const SARVECNY = 'https://stitch.csms-backup.cleversell.io/tenets/sarvecny'
export const HBSHOP_DEV = 'https://stitch.csms-dev.cleversell.io/tenets/hbshop-dev'
export const HBSHOP_PROD = 'https://stitch.csms.cleversell.io/tenets/hbshop-prod'
export const EST_STAGING = 'https://est-api.estests.com/tenets/est-staging'
export const EST_KSA = 'https://est-api.estests.com/tenets/est-ksa'
export const EST_UAE = 'https://est-api.estests.com/tenets/est-uae'
export const EST_JORDAN = 'https://est-api.estests.com/tenets/est-jordan'
export const EST_V2 = 'https://estv2-services-lb.estests.com/tenets/est-v2'
export const SUP_CLONE = 'https://api.sup-clone.qpix.io'
export const GARNELL = 'https://stitch.csms-dev.cleversell.io/tenets/garnell'
